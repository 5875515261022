export function createDefaultHeaderComponent(_ref) {
  var createElement = _ref.createElement;
  return function DefaultHeader(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      items = userProps.items,
      translations = userProps.translations;
    if (!items || items.length < 1) {
      return null;
    }
    if (!translations.title) {
      return null;
    }
    return createElement("h3", {
      className: classNames.title
    }, translations.title);
  };
}