import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["classNames", "emptyComponent", "headerComponent", "itemComponent", "layout", "items", "status", "translations", "sendEvent"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { cx } from "../lib/index.js";
import { createDefaultEmptyComponent, createDefaultHeaderComponent, createDefaultItemComponent, createListComponent } from "./recommend-shared/index.js";
export function createTrendingItemsComponent(_ref) {
  var createElement = _ref.createElement,
    Fragment = _ref.Fragment;
  return function TrendingItems(userProps) {
    var _userProps$classNames = userProps.classNames,
      classNames = _userProps$classNames === void 0 ? {} : _userProps$classNames,
      _userProps$emptyCompo = userProps.emptyComponent,
      EmptyComponent = _userProps$emptyCompo === void 0 ? createDefaultEmptyComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$emptyCompo,
      _userProps$headerComp = userProps.headerComponent,
      HeaderComponent = _userProps$headerComp === void 0 ? createDefaultHeaderComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$headerComp,
      _userProps$itemCompon = userProps.itemComponent,
      ItemComponent = _userProps$itemCompon === void 0 ? createDefaultItemComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$itemCompon,
      _userProps$layout = userProps.layout,
      Layout = _userProps$layout === void 0 ? createListComponent({
        createElement: createElement,
        Fragment: Fragment
      }) : _userProps$layout,
      items = userProps.items,
      status = userProps.status,
      userTranslations = userProps.translations,
      sendEvent = userProps.sendEvent,
      props = _objectWithoutProperties(userProps, _excluded);
    var translations = _objectSpread({
      title: 'Trending items',
      sliderLabel: 'Trending items'
    }, userTranslations);
    var cssClasses = {
      root: cx('ais-TrendingItems', classNames.root),
      emptyRoot: cx('ais-TrendingItems', classNames.root, 'ais-TrendingItems--empty', classNames.emptyRoot, props.className),
      title: cx('ais-TrendingItems-title', classNames.title),
      container: cx('ais-TrendingItems-container', classNames.container),
      list: cx('ais-TrendingItems-list', classNames.list),
      item: cx('ais-TrendingItems-item', classNames.item)
    };
    if (items.length === 0 && status === 'idle') {
      return createElement("section", _extends({}, props, {
        className: cssClasses.emptyRoot
      }), createElement(EmptyComponent, null));
    }
    return createElement("section", _extends({}, props, {
      className: cssClasses.root
    }), createElement(HeaderComponent, {
      classNames: cssClasses,
      items: items,
      translations: translations
    }), createElement(Layout, {
      classNames: cssClasses,
      itemComponent: ItemComponent,
      items: items,
      sendEvent: sendEvent
    }));
  };
}